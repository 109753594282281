

.hero-section {
	// height: 47%;
    // height: 47vh;
    // display: flex;
    // align-items: center;
}

.hero-animation{
	position: relative;
	top: 0;
	left: 0;
	width: 934px;
	height: 773px;
	// background-image: url('./assets/homepage-image.png');
	// background-repeat: no-repeat;
	// background-position: center;

	transform-origin: 0 0;

	background: rgb(238,244,255);
	background: radial-gradient(circle, rgba(238,244,255,1) 0%, rgba(255,255,255,1) 60%);

	&:after {
		content: '';
		position: absolute;
		bottom: 0;	
		left: 0;	
		width: 100%;	
		height: 1px;
		background-color: #efefef;	
		border-radius: 10px;
	}
}



.browser-wrap {
	position: absolute;
	top: 100px;
	left: 50%;
	margin-left: -250px;
	width: 500px;
}

.browser-mockup {
    position: relative;
    border: 2em solid rgba(230,230,230,0.7);
    border-width: 2em 1px 2px;
    border-radius: 3px 3px 0 0;

    &:before {
	    display: block;
	    position: absolute;
	    content: '';
	    top: -1.25em;
	    left: 1em;
	    width: .5em;
	    height: .5em;
	    border-radius: 50%;
	    background-color: #f44;
	    box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
	}

	.content {
		position: relative;
		background: #fff;
		height: 400px;

		.text-line {
			width: 200px;
			height: 10px;
			background-color: #efefef;
			border-radius: 50px;
		    box-shadow: 0 20px 0 0px #efefef, 0 80px 0 0px #efefef;
	        margin: 20px 10px;

	        &:before,
	        &:after {
	        	content: '';
	        	display: block;
	        	position: relative;
	        	top: 0;
	        	left: 0;
	        	width: 100%;
	        	height: 100%;
	        	border-radius: inherit;
	        	background-color: inherit;
	        }

	        &:before {transform: translateY(60px); width: 60%;}
	        &:after {transform: translateY(30px); width: 80%;}

	        &.right {
	        	transform: translate3d(275px, 100px, 0);
	        }
		}

		.tagline {
		    color: #d39700;
		    padding: 35px;
		    font-weight: 600;
		    letter-spacing: .8px;
		    font-size: 35px;
		    text-align: center;
		}

		.color-bubbles {
			position: absolute;
			top: 0;
			left: 0;
			width: 200px;

			svg {
				width: inherit;
			}

			&.blue {
				top: 140px;
				right: -20px;
				left: auto
			}

			&.green {
				top: 260px;
				left: -20px;
			}
		}

	}
}


.person {
	position: absolute;
	bottom: 0;
	background-size: 100%;

	&.one {
		width: 115px;
		height: 415px;
		background: url('./assets/person-standing.svg') no-repeat center bottom transparent;
		left: 90px;

		.speech {
			transform: translate3d(-60%, -50px, 0);
		
			svg {
				height: 22px !important;
			}
		}
	}


	&.two {
		width: 250px;
		height: 250px;
		background: url('./assets/person-sit.svg') no-repeat center bottom transparent;
		left: 250px;
	}

	&.three {
		width: 115px;
		height: 415px;
		background: url('./assets/person-back.svg') no-repeat center bottom transparent;
		right: 90px;

		.speech {
			transform: translate3d(30%, -70px, 0);
		
			svg {
				height: 22px !important;
			}
		}
	}


	.speech {
		width: 150px;
		border-color: #efefef;
		padding: 10px;

		&.sb7:before {
			border-left-color: #efefef;
			border-top-color: #efefef;
		}

		&.sb8:before {
			border-right-color: #efefef;
			border-top-color: #efefef;
		}	
	}



}

@keyframes shine {
  0% { opacity: 1; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 1;  transform: scale(1);}
}


@import './SpeechBubble.scss';



.bubbles {
	position: absolute;
	width: 25px;
	height: 25px;
	// border-radius: 50%;
	background-color: transparent;
	// box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.32);
	// transform-origin: center;
	// transform: scale(.8);

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 110%;
	}

	&.heart {
		animation: fadeup 2s infinite linear; 

		&:before{background-image: url('./assets/love.svg');}
		
		&:nth-child(1) { top: 50px; left: 0px; }
		&:nth-child(2) { 
			&:before {transform: rotate(45deg);}

			top: 35px; 
			right: 30px; 
			animation-delay: 1s; 
		}
	}
	
	&.like { 
		animation: fadeup 3s infinite linear; 

		&:before{background-image: url('./assets/like.svg');} 
		
		&:nth-child(3) { 
			&:before {transform: rotate(-45deg); }

			top: 35px; 
			right: 0; 
			animation-delay: .5s; 
		}

		&:nth-child(4) { top: 80px; left: 80px; animation-delay: 2s; }
	}

}


@keyframes fadeup {
  0% {transform: translateY(50px); opacity: 0;}
  10% {opacity: 0;}
  50% {transform: translateY(0); opacity: 1;}
  90% {opacity: 0;}
  100% {transform: translateY(-50px); opacity: 0;}
}


.react-rotating-text-cursor {
	font-weight: 200;
	animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}
 
@keyframes blinking-cursor {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}