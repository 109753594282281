

$wreathColor: #ffe4a0;

.wreath {
	position: relative;
	text-align: center;
	display: block;
	color: $wreathColor;

	
	.text {
		display: inline-block;
		position: relative;
		font-weight: 600;
		letter-spacing: .8px;
		line-height: 20px;
		text-align: center;
		min-width: 217px;

		&:after,
		&:before {
			content: '';
			position: absolute;
			top: 0;
			width: 30px;
			height: 100%;
			background-color: transparent;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 30px;
		}

		&:before {
			background-image: url('./wreath-left.svg');
			left: 0;
		}

		&:after {
			background-image: url('./wreath-right.svg');
			right: 0;
		}

		.stars {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 25px;
			background: url('./wreath-stars.svg') no-repeat center transparent;
			background-size: 75px;
		}

		.secondary {
			color: darken($wreathColor, 30%);
			padding: 35px;
			padding-bottom: 0;
			font-size: 14px;
			font-weight: 400;
		}

		.primary {
			color: darken($wreathColor, 40%);
			padding: 35px;
			padding-top: 0;
			font-size: 16px;
			text-transform: uppercase;
		}
		
	}

}