#home-nav {
    padding: .7rem 1rem;

    @media (min-width: 992px){
	    padding-top: 25px;
	    padding-bottom: 25px;
	    transition: padding-top .3s,padding-bottom .3s;
	    background-color: transparent;
	}

	&.nav-shrink {
		// @media (min-width: 992px) {
		    padding-top: .5rem;
		    padding-bottom: .5rem;
			background-color: rgba(255,255,255,0.99);
			box-shadow: 0 1px 9px rgba(0,0,0,0.05);
		// }
	}

	.nav-link {
	    font-weight: 600;
	    font-size: 90%;
	    letter-spacing: 1px;
	    text-transform: uppercase;
	}

	.btn {
	    font-weight: 600;
	    font-size: 90%;
	    letter-spacing: 1px;
	    text-transform: uppercase;
	    border-radius: 2px;
	}
}