
html {

	.Review,
	&.Review {
		background-color: #f0f0f0 !important;
	}
}

.Review {
	body {
		background-color: #f0f0f0 !important;
		padding-top: 65px;
	}
}


.feedback-form {

	.title {
		font-size: 1.9rem;
		font-weight: 300;
	    line-height: 40px;
	}

	.description {
		color: #626881;
		// line-height: 2;
		letter-spacing: .5px;
		font-weight: 400;
		font-size: 16px;
	}

	label {
		text-transform: uppercase;
		font-size: 70%;
		color: #6c757d!important;
		font-weight: 600 !important;
		letter-spacing: .8px;
	    margin-bottom: .1rem;

	}

	.multichoice {
		.custom-control{
			display: block;
		}
	    
	    .custom-control-label {
	    	text-transform: none;
	    }
	}

	.btn {
		text-transform: uppercase;
		letter-spacing: .8px;
		font-weight: 600;
	}
}